<template>
  <div>
    <section class="section">
      <div class="container mt-60">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
            <library :content.sync="content" />
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
            <div class="section-title">
              <div class="media features">
                <div class="icons m-0 rounded h1 text-primary px-3">
                  <i class="uil uil-sitemap"></i>
                </div>
              </div>
              <h5 class="title">จัดการกลุ่มของคำถาม</h5>
              <p class="border-bottom"></p>
              <h5 class="text-primary">วิธีกำหนดกลุ่มคำถามให้กับข้อสอบ</h5>
            </div>

            <div class="row justify-content-center">
              <div class="col-12">
                <b-tabs
                  pills
                  justified
                  content-class="mt-4 pt-2"
                  nav-wrapper-class=""
                >
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">
                          ประเภทตัวเลือกเดียว
                        </p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดแท็บ "Question"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal1>
                          <img
                            src="/images/screen/course/exam/tagLevel/1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal1"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row pl-3 mt-5">
                      <h6>
                        การเพิ่มคำถามมี 2 แบบ คือ 1.การเพิ่มคำถามด้วยตนเอง
                        2.การเพิ่มคำถามโดยการนำเข้าไฟล์คำถาม
                      </h6>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              การเพิ่มคำถามด้วยตนเอง เริ่มจากกดปุ่ม "New
                              Question"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal2>
                          <img
                            src="/images/screen/course/exam/tagLevel/2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal2"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกคำถามประเภทตัวเลือกเดียว
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal3>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal3"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกกลุ่มคำถามที่ต้องการกำหนดให้กับข้อสอบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal4>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal4"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กรอกรายละเอียดของคำถาม และกดปุ่มบันทึก
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal5>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal5"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">6</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              การเพิ่มคำถามโดยการนำเข้าไฟล์คำถาม เริ่มจากกดปุุ่ม
                              "Import Exam"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal6>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/4.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal6"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/4.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">7</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกคำถามประเภทตัวเลือกเดียว
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal7>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/5.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal7"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/5.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">8</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ตารางแสดงรายการกลุ่มคำถาม และแสดง "ID"
                              เพื่อให้ผู้ใช้คัดลอก ID
                              ใส่ไว้ในไฟล์คำถามเพ้่อจำแนกประเภทกลุ่มคำถาม
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal8>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/6.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal8"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/6.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">9</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Download Sample"
                              เพื่อดูตัวอย่างแบบฟอร์มการนำเข้าข้อสอบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal9>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/7.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal9"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/7.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">10</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดเลือกไฟล์เพื่อนำเข้าข้อสอบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal10>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/8.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal10"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/8.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">11</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เมื่อนำเข้าข้อสอบแล้ว รายการคำถามจะปรากฏด้านล่าง
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal11>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/9.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal11"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/9.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">12</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Confirm Importing" บันทึกข้อมูล
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal12>
                          <img
                            src="/images/screen/course/exam/tagLevel/single/10.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal12"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/single/10.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">
                          ประเภทหลายตัวเลือก
                        </p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดแท็บ "Question"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal13>
                          <img
                            src="/images/screen/course/exam/tagLevel/1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal13"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row pl-3 mt-5">
                      <h6>
                        การเพิ่มคำถามมี 2 แบบ คือ 1.การเพิ่มคำถามด้วยตนเอง
                        2.การเพิ่มคำถามโดยการนำเข้าไฟล์คำถาม
                      </h6>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              การเพิ่มคำถามด้วยตนเอง เริ่มจากกดปุ่ม "New
                              Question"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal14>
                          <img
                            src="/images/screen/course/exam/tagLevel/2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal14"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกคำถามประเภทหลายตัวเลือก
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal15>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal15"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกกลุ่มคำถามที่ต้องการกำหนดให้กับข้อสอบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal16>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal16"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กรอกรายละเอียดของคำถาม และกดปุ่มบันทึก
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal17>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal17"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">6</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              การเพิ่มคำถามโดยการนำเข้าไฟล์คำถาม เริ่มจากกดปุุ่ม
                              "Import Exam"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal18>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/4.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal18"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/4.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">7</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกคำถามประเภทหลายตัวเลือก
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal19>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/5.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal19"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/5.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">8</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ตารางแสดงรายการกลุ่มคำถาม และแสดง "ID"
                              เพื่อให้ผู้ใช้คัดลอก ID
                              ใส่ไว้ในไฟล์คำถามเพ้่อจำแนกประเภทกลุ่มคำถาม
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal20>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/6.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal20"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/6.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">9</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Download Sample"
                              เพื่อดูตัวอย่างแบบฟอร์มการนำเข้าข้อสอบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal21>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/7.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal21"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/7.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">10</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดเลือกไฟล์เพื่อนำเข้าข้อสอบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal22>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/8.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal22"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/8.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">11</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เมื่อนำเข้าข้อสอบแล้ว รายการคำถามจะปรากฏด้านล่าง
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal23>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/9.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal23"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/9.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">12</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Confirm Importing" บันทึกข้อมูล
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal24>
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/10.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal24"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/multiple/10.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">
                          ประเภทเติมคำ
                        </p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดแท็บ "Question"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal25>
                          <img
                            src="/images/screen/course/exam/tagLevel/1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal25"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่ม "New Question"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal26>
                          <img
                            src="/images/screen/course/exam/tagLevel/2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal26"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">เลือกคำถามประเภทเติมคำ</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal27>
                          <img
                            src="/images/screen/course/exam/tagLevel/filltheblank/1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal27"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/filltheblank/1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกกลุ่มคำถามที่ต้องการกำหนดให้กับข้อสอบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal28>
                          <img
                            src="/images/screen/course/exam/tagLevel/filltheblank/2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal28"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/filltheblank/2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กรอกรายละเอียดของคำถาม และกดปุ่มบันทึก
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal29>
                          <img
                            src="/images/screen/course/exam/tagLevel/filltheblank/3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal29"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/tagLevel/filltheblank/3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import library from "@/views/library/library.vue";
export default {
  data() {
    return {
      content: "examWithTagLevel",
    };
  },
  components: {
    library,
  },
};
</script>
